import * as React from "react";

import { FaMicrophone } from "react-icons/fa";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Typography, IconButton } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import NoEncryptionGmailerrorredOutlinedIcon from "@mui/icons-material/NoEncryptionGmailerrorredOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const chunkSize = 8;

export default function VirtualizedList({
  remoteData,
  boxRef,
  selectedSongs,
  handledes,
  handleOpen,
  fontSize,
  isComputePage,
  isFinalPage,
}) {
  return remoteData.map((chunk, index) => (
    <div
      style={{
        overflow: "hidden",
        overflowY: "scroll",
        height: "300px",
        margin: "0 0 20px 0",
      }}
      key={index}
    >
      <Box
        alignItems="center"
        justifyContent="center"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          marginBottom: 1,
          marginRight: "10px",
          width: window.innerWidth >= 769 ? "300px" : "200px",
          // maxWidth: 500, // Pour permettre deux boxes par ligne
        }}
      >
        {chunk.map(
          (
            { _id, title, artist, isSelected, position, parole, songId },
            innerIndex
          ) => (
            <Box
              key={_id}
              ref={boxRef}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "inherit", // Largeur ajustée pour inclure la marge entre les boîtes
                height: window.innerWidth >= 769 ? 50 : 40,

                // background: selectedSongs[index * chunkSize + innerIndex]
                //   ? "rgb(255, 142, 108)"
                //   : "rgb(255, 142, 108)",
                background:
                  isSelected && isFinalPage
                    ? "rgb(255, 255, 255)"
                    : isSelected
                    ? "rgb(255, 255, 255, 0.6)"
                    : "rgb(255, 142, 108)",
                opacity: selectedSongs[index * chunkSize + innerIndex]
                  ? 1
                  : isSelected
                  ? 1
                  : "", // Opacité réduite si aucune chanson n'est sélectionnée
                padding: 1,
                borderRadius: 0.5,
                position: "relative",
              }}
            >
              {!isSelected ? (
                <div
                  onClick={() =>
                    handleOpen(index * chunkSize + innerIndex, songId)
                  }
                  style={{
                    cursor: "pointer",
                    width: "-webkit-fill-available",
                  }}
                >
                  {isComputePage ? (
                    <center>
                      <QuestionMarkIcon
                        fontSize="large"
                        style={{ color: "#13478A" }}
                      />
                    </center>
                  ) : selectedSongs[index * chunkSize + innerIndex] ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "30px",
                      }}
                    >
                      <Typography
                        variant="h7"
                        component="h5"
                        sx={{
                          fontSize: window.innerWidth >= 769 ? "15px" : `8px`,
                          color: "#451c22",
                          // fontFamily: "Caros Soft Medium",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "fit-content",
                          paddingRight: "10px",
                          marginBottom: "30px",
                        }}
                      >
                        {position < 10 ? "0" + position : position}
                      </Typography>

                      <Box>
                        <Typography
                          variant="h7"
                          component="h5"
                          sx={{
                            fontSize:
                              window.innerWidth >= 769
                                ? "15px"
                                : `${fontSize}px`,
                            fontWeight: "bold",
                            color: "black",
                            overflow: "hidden",
                            wordWrap: "break-word",
                            maxWidth:
                              window.innerWidth >= 769 ? "215px" : "120px",
                            textAlign: "center",
                          }}
                        >
                          {selectedSongs[index * chunkSize + innerIndex].title}
                        </Typography>
                      </Box>
                      <IconButton size="sm" sx={{ color: "#fff", padding: 0 }}>
                        <DoneIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h7"
                        component="h5"
                        sx={{
                          fontSize: window.innerWidth >= 769 ? "15px" : `8px`,
                          color: "#fff",
                          // fontFamily: "Caros Soft Medium",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          wordWrap: "break-word",
                          width: "fit-content",
                          marginBottom: "30px",
                          position: "absolute",
                          left: "0",
                          marginLeft: "8px",
                        }}
                      >
                        {position < 10 ? "0" + position : position}
                      </Typography>
                      <Typography
                        variant="h7"
                        component="h5"
                        sx={{
                          fontSize: window.innerWidth >= 769 ? "15px" : "12px",
                          color: "#fff !important",
                          // fontFamily: "Caros Soft Medium",
                          textTransform: "uppercase",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        cliquez ici
                      </Typography>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <Typography
                    variant="h7"
                    component="h5"
                    sx={{
                      fontSize: window.innerWidth >= 769 ? "15px" : `8px`,
                      color: "#451c22",
                      // fontFamily: "Caros Soft Medium",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      wordWrap: "break-word",
                      width: "fit-content",
                      paddingRight: "10px",
                      marginBottom: "30px",
                    }}
                  >
                    {position < 10 ? "0" + position : position}
                  </Typography>
                  <Box>
                    <Typography
                      variant="h7"
                      component="h5"
                      sx={{
                        fontSize:
                          window.innerWidth <= 375
                            ? "9px"
                            : window.innerWidth < 768
                            ? "10px"
                            : window.innerWidth >= 769
                            ? "15px"
                            : `${fontSize}px`,
                        maxWidth: window.innerWidth >= 769 ? "215px" : "120px",
                        fontWeight: "bold",
                        color: "#451c22",
                        // fontFamily: "Caros Soft Medium",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        wordWrap: "break-word",
                        textAlign: "center",
                      }}
                    >
                      {title}
                    </Typography>
                  </Box>
                  {isSelected && isFinalPage ? (
                    <FaMicrophone
                      // color={highlighted ? "yellow" : "#c390a0"}
                      color="#000"
                      size={25}
                      onClick={() => handledes(parole, title)}
                      cursor={"pointer"}
                      // style={{
                      //   top: window.innerWidth >= 769 ? "30%" : "20%",
                      //   right: 0,
                      //   position: "absolute",
                      // }}
                    />
                  ) : (
                    <LockOutlinedIcon />
                  )}
                </>
              )}
            </Box>
          )
        )}
      </Box>
    </div>
  ));
}
