import React, { useState, useEffect } from "react";
import BackgroundImage from "../BackgroundImage ";
import ImageBackground from "../assets/Jukebox_mobile_black.jpg";
import ImageBackgroundWeb from "../assets/jukebox_black.jpg";
import { Box, Typography, Button } from "@mui/material";
import { FetchedData } from "../dataSong";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import useSWR from "swr";
import NoEncryptionGmailerrorredOutlinedIcon from "@mui/icons-material/NoEncryptionGmailerrorredOutlined";

const HintPage = () => {
  const [videoContent, setVideoContent] = useState(null);
  const [endTime, setEndTime] = useState(new Date().getTime() + 1 * 60 * 1000);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [formatStartVoteDate, setFormatStartVoteDate] = useState(null);
  const [formatEndVoteDate, setFormatEndVoteDate] = useState(null);
  const fetcher = (...args) => fetch(...args).then((res) => res.json());
  const url = `${process.env.REACT_APP_DOMAIN}/api/endVoteTime`;
  const [infoPlayList, setInfoPlaylist] = useState([]);
  const [formaData, setFormData] = useState("");

  const { logout } = useAuth();

  const { data, error, isLoading } = useSWR(url, fetcher, {
    revalidateOnFocus: true,
  });
  if (data?.result === true) {
    logout();
  }
  if (data?.gotCode !== undefined) {
    if (data?.gotCode !== localStorage.getItem("currentpass")) {
      // console.log("current password:", localStorage.getItem("currentpass"));
      // console.log("passfrom store:", data?.gotCode);
      // console.log("date reached from pass");
      logout();
    }
  }

  // console.log("data from swr:", data);

  const fetchedData = async () => {
    try {
      let content = localStorage.getItem("videoContent");
      // let content =
      //   "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4";
      content.replace(/\/+$/, "");
      setVideoContent(`${process.env.REACT_APP_DOMAIN}/static/${content}`);

      const results = await FetchedData();
      const originalDate = results.playListInfo.dateFinVote;
      const dateDebutVote = results.playListInfo.dateDebutVote;
      const dateFinVote = results.playListInfo.dateFinVote;
      const formattedDate = moment(originalDate)
        .locale("fr")
        .format("DD MMMM YYYY");
      if (moment(dateDebutVote).isSame(new Date(), "day")) {
        // console.log("Same date");
        setFormatStartVoteDate(moment.utc(dateDebutVote).format("HH:mm"));
        setFormatEndVoteDate(moment.utc(dateFinVote).format("HH:mm"));
      } else {
        setFormatStartVoteDate(
          moment.utc(dateDebutVote).format("MMMM Do YYYY, HH:mm")
        );
        setFormatEndVoteDate(
          moment.utc(dateFinVote).format("MMMM Do YYYY, HH:mm")
        );
      }
      setFormData(formattedDate);
      setInfoPlaylist(results.playListInfo);
      setEndTime(moment(originalDate.toString().slice(0, -1)).utc().valueOf());
    } catch (error) {
      console.error("Internal occured error : ", error);
    }
  };
  useEffect(() => {
    fetchedData();
  }, []);

  const [isReady, setIsReady] = useState(false);
  const [years, setYear] = useState("2025");
  const handleOnReady = () => {
    setIsReady(true);
  };
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const remaining = endTime - currentTime;
      if (remaining < 1000) navigate("/finalplaylist");
      setTimeRemaining(remaining);
    }, 1000); // Mettre à jour toutes les secondes

    return () => clearInterval(interval); // Nettoyer le setInterval lorsque le composant est démonté
  }, [endTime]); // [endTime] pour réinitialiser l'interval lorsque l'heure de fin change

  const hours = Math.floor(timeRemaining / (1000 * 60 * 60));
  const minutes = Math.floor((timeRemaining / (1000 * 60)) % 60);
  const seconds = Math.floor((timeRemaining / 1000) % 60);

  const formattedTime = () => (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {hours !== 0 && (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontSize: "larger", height: "33px" }}>
              {("0" + hours).slice(-3)}
            </span>
            <span style={{ fontSize: "xx-small" }}>hour</span>
          </div>
          <span style={{ margin: "0px 5px" }}>:</span>
        </>
      )}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ fontSize: "larger", height: "33px" }}>
          {("0" + minutes).slice(-2)}
        </span>
        <span style={{ fontSize: "xx-small" }}>mins</span>
      </div>
      <span style={{ margin: "0px 5px" }}>:</span>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ fontSize: "larger", height: "33px" }}>
          {("0" + seconds).slice(-2)}
        </span>
        <span style={{ fontSize: "xx-small" }}>secs</span>
      </div>
    </div>
  );
  return (
    <BackgroundImage
      imageUrlMobile={ImageBackground}
      imageUrlDesktop={ImageBackgroundWeb}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "auto",
          alignContent: "center",
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            height: 40,
            "@media (min-height:740px)": {
              marginBottom: "50px",
            },
            "@media (min-height:800px)": {
              marginBottom: "84px",
            },
            "@media (min-height:900px)": {
              marginBottom: "100px",
            },
          }}
        >
          <Typography
            style={{
              color: "#FF8E6C",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              lineHeight: "18px",
            }}
          >
            <span className="poppins" style={{ fontWeight: "bold" }}>
              {infoPlayList?.nomplaylis}
            </span>
            <span
              className="poppins"
              style={{
                fontWeight: "bold",
                textShadow: "rgb(0, 0, 0) 1px 1px 5px",
              }}
            >
              {formaData}
            </span>
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="h2"
            style={{
              color: "rgb(251, 221, 46, 0.9)",
              textAlign: "center",
              textShadow: "rgb(0, 0, 0) 1px 1px 7px",
            }}
          >
            <span className="budmo">JUKE BOX</span>
          </Typography>
          <Typography
            variant="h2"
            style={{
              color: "#fff",
              textAlign: "center",
              marginTop: -28,
              marginBottom: 10,
            }}
          >
            <span
              className="budmo"
              style={{
                color: "rgb(251, 221, 46, 0.9)",
                textShadow: "rgb(0, 0, 0) 1px 1px 7px",
              }}
            >
              TOUR <span className="octothorpe">{years}</span>
            </span>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "-1rem",
            marginBottom: "10px",
          }}
        >
          <Typography
            variant="h5"
            component="h4"
            sx={{
              background: "#FFFFFF",
              padding: 1,
              borderRadius: 2,
              color: "rgb(255, 142, 108)",
              textAlign: "center",
              width: "100%",
            }}
          >
            <span className="scuba">{formattedTime()}</span>
          </Typography>
        </Box>
        <Typography
          variant="h7"
          component="h6"
          style={{ color: "#fff", textAlign: "center" }}
          gutterBottom
          fontSize={"16px"}
        >
          <span>Ouverture des votes à {formatStartVoteDate}</span>
          <br />
          <span>Fermeture des votes à {formatEndVoteDate}</span>
        </Typography>
        <Box sx={{ display: "contents" }}>
          <Typography
            variant="h6"
            sx={{
              color: "white",
              background: "#000",
              padding: "20px",
              width: "64%",
              textAlign: "center",
              fontSize: "large",
              marginBottom: "10px",
              display: "inline-block",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Typography sx={{ marginBottom: "1px", fontSize: "inherit" }}>
              A vous de voter :
            </Typography>{" "}
            <br />
            <Typography sx={{ marginBottom: "25px", fontSize: "inherit" }}>
              Compléter la playlist en cliquant sur les{" "}
              <span
                style={{
                  backgroundColor: "rgb(255, 142, 108)",
                  display: "inline-block",
                  borderRadius: "2px",
                }}
              >
                cases orange
              </span>{" "}
            </Typography>
            Les titres dans les{" "}
            <span
              style={{
                backgroundColor: "gray",
                display: "inline-block",
                borderRadius: "2px",
              }}
            >
              cases grisées
            </span>{" "}
            font déjà partie de la playlist
          </Typography>
        </Box>
        <Button
          variant="contained"
          size="large"
          style={{
            borderRadius: "50px", // Arrondir les coins
            color: "rgb(255, 142, 108, 0.9)",
            fontWeight: "bold",
            textTransform: "none",
            borderBottom: "5px solid rgb(255, 142, 108, 0.9)",
            backgroundColor: "#fff",
          }}
          onClick={handleClick}
        >
          Accéder
        </Button>
      </Box>
    </BackgroundImage>
  );
};

export default HintPage;
