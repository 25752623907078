import React, { useState, useEffect } from "react";
import { Spinner } from "@chakra-ui/react";

const BackgroundImage = ({ imageUrlMobile, imageUrlDesktop, children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const imageUrl = window.innerWidth >= 769 ? imageUrlDesktop : imageUrlMobile;
  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => setIsLoading(false);
    img.onerror = () => setIsLoading(false);
  }, [imageUrl]);

  const backgroundStyle = {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "auto",
    height: "100vh",
  };

  return (
    <div
      style={{
        ...backgroundStyle,
        backgroundImage: `url(${imageUrl})`,
        backgroundColor: "#000",
      }}
    >
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "2rem",
            fontWeight: "bold",
            color: "white",
          }}
        >
          <Spinner
            thickness="30px"
            speed="0.65s"
            // emptyColor='gray.200'
            color="#A14699"
            size="xl"
          />
        </div>
      )}
      {!isLoading && children}
    </div>
  );
};

export default BackgroundImage;
