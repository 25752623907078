import { Center, Spinner } from "@chakra-ui/react";
import { Box, Typography } from "@mui/material";

import { useState, useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import BackgroundImage from "../BackgroundImage ";
import ImageBackground from "../assets/Jukebox_mobile_black.jpg";
import ImageBackgroundWeb from "../assets/jukebox_black.jpg";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FilledInput from "@mui/material/FilledInput";
import Button from "@mui/material/Button";

export const LoginPage = () => {
  const [username, setUsername] = useState("");
  // const [password, setPassword] = useState("");
  const [message, setMessage] = useState(false);
  const [years, setYear] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const { login } = useAuth();
  const value = 4;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    setYear("2025");
  }, []);
  const handleLogin = async (e) => {
    e.preventDefault();
    setisLoading(true);
    const results = await login({ username });

    if (results.status === 403) {
      setMessage("Code Incorrect");
      setisLoading(false);
    } else {
      setisLoading(false);
    }
    if (results.status === 404) {
      setMessage("Concert Terminé");
      setisLoading(false);
    } else {
      setisLoading(false);
    }
  };

  return (
    <BackgroundImage
      imageUrlMobile={ImageBackground}
      imageUrlDesktop={ImageBackgroundWeb}
      // style={{ backgroundSize: "100%",
      // backgroundAttachment: "fixed",
      // width: "100%",
      // height: "100vh",}}
    >
      <Typography
        variant="h2"
        style={{
          color: "rgb(251, 221, 46, 0.9)",
          textAlign: "center",
          textShadow: "rgb(0, 0, 0) 1px 1px 7px",
        }}
      >
        <span className="budmo">JUKE BOX</span>
      </Typography>
      <Typography
        variant="h2"
        style={{
          color: "rgb(251, 221, 46, 0.9)",
          textAlign: "center",
          marginTop: -28,
          marginBottom: -80,
          textShadow: "rgb(0, 0, 0) 1px 1px 7px",
        }}
      >
        <span className="budmo">
          TOUR <span className="octothorpe">{years}</span>
        </span>
      </Typography>

      <div
        className="login-container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <form
          onSubmit={handleLogin}
          style={{
            width: "300px",
            padding: "20px",
            // border: "1px solid #ccc",
            borderRadius: "4px",
            backgroundColor: "transparent",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isLoading && (
              <Spinner
                thickness="30px"
                speed="0.65s"
                // emptyColor='gray.200'
                color="#A14699"
                size="xl"
              />
            )}
          </div>

          <div
            className="form-group"
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <label
              htmlFor="Code"
              style={{
                display: "block",
                marginBottom: "5px",
                fontSize: "15px",
                fontWeight: "bold",
                textAlign: "center",
                color: "#FFFFFF",
                backgroundColor: "#000",
                borderRadius: "10px",
                padding: "5px 10px",
              }}
            >
              Entrez le code pour accéder à la playlist et sélectionner les
              titres de votre choix
            </label>
            <br />
            {/* <input
              style={{
                width: "100%",
                color: "black",
                maxLength: { value },
                padding: "8px",
                placeholder: "Veuillez entrer votre code",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
              id="username"
              type="text"
              // value={username}
              // onChange={(e) => setUsername(e.target.value)}
            /> */}
            <FormControl sx={{ m: 1, width: "25ch" }} variant="filled">
              <InputLabel
                style={{ color: message ? "red" : "white" }}
                htmlFor="filled-adornment-password"
              >
                {message ? "Incorrect" : "Entrez le code"}
              </InputLabel>
              <FilledInput
                error={message ? true : false}
                onFocus={() => setMessage(false)}
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                }}
                sx={{
                  '& input[type="password"]::-ms-reveal': { display: "none" },
                }}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                color="warning"
                id="filled-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>

          <br />
          <br />
          {/* <button
            type="submit"
            style={{
              width: "100%",
              padding: "8px",
              border: "none",
              fontWeight: "bold",
              textTransform: "none",
              borderRadius: "50px", // Arrondir les coins
              backgroundColor: "#FFFFFF",
              borderBottom: "5px solid #13478A",
              fontFamily: "Caros Soft Medium",
              color: "#451c22",
              cursor: "pointer",
            }}
          >
            Se connecter
          </button> */}
          <Button
            type="submit"
            variant="contained"
            size="large"
            disabled={message ? true : false}
            style={{
              borderRadius: "50px", // Arrondir les coins
              backgroundColor: message ? "rgba(255, 142, 108, 0.5)" : "#FFFFFF", // Couleur de fond
              color: message ? "gray" : "rgb(255, 142, 108)",
              fontWeight: "bold",
              textTransform: "none",
              borderBottom: "5px solid rgb(255, 142, 108, 0.9)",
              width: "62%",
            }}
          >
            Se connecter
          </Button>
          {message !== false && (
            <div
              style={{
                textAlign: "center",
                color: "red",
              }}
            >
              {message}
            </div>
          )}
        </form>
      </div>
    </BackgroundImage>
  );
};
