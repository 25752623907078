import React, { useEffect, useState } from "react";
import {
  Spinner,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Text,
} from "@chakra-ui/react";
import Anime from "../animations/Anime";
import { ChakraProvider } from "@chakra-ui/react";

const CustomModal = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  isRequired,
  hasSubscribed,
}) => {
  const colorList = [
    "rgb(255, 193, 122, 0.9)",
    "rgb(255, 89, 148, 0.9)",
    "rgb(255, 23, 83, 0.9)",
    "rgb(255, 142, 108, 0.9)",
    "rgb(255, 155, 133, 0.9)",
    "rgb(255, 28, 91, 0.9)",
    "rgb(255, 52, 78, 0.9)",
  ];
  const [colorIndex, setColorIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setColorIndex((colorIndex + 1) % colorList.length);
    }, 200);
    return () => clearInterval(intervalId);
  }, [colorIndex, colorList.length]);
  return (
    <ChakraProvider>
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size="xs">
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(10deg)"
        />
        <ModalContent
          bg={!isRequired ? colorList[colorIndex] : "rgb(255, 142, 108, 0.9)"}
          color="#FFFFFF"
          roundedTop={10}
          sx={{
            alignItems: "center",
          }}
        >
          {isRequired && (
            <ModalCloseButton color="#FFFFFF">
              <Text>retour</Text>
            </ModalCloseButton>
          )}

          <ModalBody
            p={5}
            sx={{
              alignItems: "center",
            }}
          >
            {!isRequired ? (
              <Text
                sx={{
                  fontSize: "28px",
                  marginTop: "30px",
                  textAlign: "center",
                }}
              >
                Félicitations ! <br /> Dans quelques instants vous allez
                découvrir la playlist avec les titres les plus demandés
              </Text>
            ) : (
              <Text
                sx={{
                  fontSize: "28px",
                  marginTop: "30px",
                  textAlign: "center",
                }}
              >
                Pour valider votre playlist, vous devez sélectionner tous les
                titres
              </Text>
            )}
          </ModalBody>
          <ModalFooter
            sx={{
              justifyContent: "center",
            }}
          >
            {
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="#43111E"
                size="x"
              />
            }
          </ModalFooter>
          {/* {hasSubscribed && <Anime />} */}
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default CustomModal;
