import "../App.css";
import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "@chakra-ui/react";
import { Box, Button, Modal, Typography, IconButton } from "@mui/material";
import Favorite from "@mui/icons-material/Favorite";
import BackgroundImage from "../BackgroundImage ";
import ImageBackground from "../assets/Jukebox_mobile_black.jpg";
import ImageBackgroundWeb from "../assets/jukebox_black.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoClose } from "react-icons/io5";
import { FetchedData } from "../dataSong";
import { PostData } from "../dataSong";
import CustomModal from "../components/modal/customModal";
import moment from "moment";
import "moment/locale/fr";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import SpeechModal from "../components/modal/SpeechModal";
import VirtualizedList from "../components/VirtualizedList";
// import io from "socket.io-client";

const Home = () => {
  const [selectedSongs, setSelectedSongs] = useState(Array(6).fill(null));
  const [open, setOpen] = useState(false);
  const [selectedGrid, setSelectedGrid] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [startVoteTime, setStartVoteTime] = useState(
    new Date().getTime() + 1 * 15 * 1000
  );
  const [formatStartVoteDate, setFormatStartVoteDate] = useState(null);
  const [formatEndVoteDate, setFormatEndVoteDate] = useState(null);
  const [isVoteTime, setIsVoteTime] = useState(false);
  const [endVoteTime, setEndVoteTime] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [fontSize, setFontSize] = useState(10); // Taille de police initiale
  const [isOverflow, setIsOverflow] = useState(false); // État pour indiquer si le texte dépasse
  const boxRef = useRef(null); // Référence pour la boîte
  const textRef = useRef(null); // Référence pour le texte
  const [infoPlayList, setInfoPlaylist] = useState([]);
  const [formaData, setFormData] = useState("");
  const [years, setYear] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [myPlayListId, setMyPlalistId] = useState(null);
  const [subscribed, setSubscribed] = useState(false);

  //
  const [isOpenModal, setModal] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [remoteData, setRemoteData] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [tabFinalMusic, setTabFinalMusic] = useState([]);
  const [tabCompare, setTabCompare] = useState([]);
  const [isrequired, setIsRequired] = useState(false);

  const [isCustomOpenModal, setCustomModal] = useState(false);

  const handleCustomCloseModal = () => {
    setCustomModal(false);
  };

  const { logout, updateUserStatus } = useAuth();

  const calculatePercentages = (dataArray) => {
    const totalCount = dataArray.reduce(
      (accumulator, currentValue) => accumulator + currentValue.voteCount,
      0
    );
    const percentages = dataArray.map((dataItem) => {
      const percentage =
        totalCount === 0
          ? 0
          : Math.round((dataItem.voteCount / totalCount) * 100);
      return { ...dataItem, percentage };
    });
    return percentages;
  };

  //socket management
  // const socket = io("http://localhost:3002");
  // const sendSocketEvent = () => {
  //   socket.emit("chat message", "hello server");
  // };
  // socket.on("chat message", (message) => {
  //   fetchedData();
  // });

  useEffect(() => {
    fetchedData();
  }, []);
  // fetch data
  const fetchedData = async () => {
    // console.log("HasVoted", localStorage.getItem("hasUserVoted"));
    try {
      // setIsLoadingData(true);
      const results = await FetchedData();
      // console.log("fetched results ", results);
      const filteredResults = results.getmusics.filter(
        (c) => !c.isAdded && c.isChoice
      );

      setMyPlalistId(results.playListInfo.id_playlist);
      localStorage.setItem("videoContent", results.playListInfo.videoUrl);

      const originalDate = results.playListInfo.dateCreation;
      const dateDebutVote = results.playListInfo.dateDebutVote;
      const dateFinVote = results.playListInfo.dateFinVote;
      const formattedDate = moment(originalDate)
        .locale("fr")
        .format("DD MMMM YYYY");
      if (moment(dateDebutVote).isSame(new Date(), "day")) {
        // console.log("Same date");
        setFormatStartVoteDate(moment.utc(dateDebutVote).format("HH:mm"));
        setFormatEndVoteDate(moment.utc(dateFinVote).format("HH:mm"));
      } else {
        setFormatStartVoteDate(
          moment.utc(dateDebutVote).format("MMMM Do YYYY, HH:mm")
        );
        setFormatEndVoteDate(
          moment.utc(dateFinVote).format("MMMM Do YYYY, HH:mm")
        );
      }

      // Get the current date

      // Calculate the difference in hours between the current date and the original date
      setEndTime(new Date(originalDate).getTime());
      setStartVoteTime(
        moment(dateDebutVote.toString().slice(0, -1)).utc().valueOf()
      );
      setEndVoteTime(
        moment(dateFinVote.toString().slice(0, -1)).utc().valueOf()
      );

      setFormData(formattedDate);
      setYear("2025");

      setInfoPlaylist(results.playListInfo);
      const tabchoice = [];
      const chunkedData = [];

      chunkedData.push(filteredResults);
      chunkedData.forEach((array) => {
        array.sort((a, b) => a.position - b.position);
      });
      setRemoteData(chunkedData);
      // console.log("chunked", chunkedData);

      chunkedData.forEach((element) => {
        element.forEach((elt) => {
          if (elt.songId.length > 0) {
            tabchoice.push(elt);
          }
        });
      });
      // console.log("taille ===> ", tabchoice.length);
      setTabCompare(tabchoice);
      // console.log("chunked", chunkedData);

      if (filteredResults.length > 0) {
        setIsLoadingData(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSongSelection = async () => {
    if (tabCompare.length <= tabFinalMusic.length) {
      // setIsModalOpen(true);
      setIsRequired(false);
      // console.log("confirm modal: ");
      await handleConfirmModal();
    } else {
      setIsRequired(true);
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModal(false);
  };

  const handleConfirmModal = async () => {
    try {
      // setIsLoading(true);
      setIsModalOpen(true);

      // console.log("valiade =======> ", JSON.stringify(tabFinalMusic));
      const response = await PostData(tabFinalMusic);
      console.log("from postData response", JSON.stringify(response));
      console.log("tabFinalMusic:", tabFinalMusic);

      //update user vote status
      if (response.message === "insertion reussie") {
        // sendSocketEvent();
        console.log("waiting...");

        setSubscribed(true);

        setTimeout(() => {
          updateUserStatus();
          // navigate("/videoPage")
        }, 3000);
      }
      // Utilisez la réponse si nécessaire
    } catch (error) {
      console.error(error);
    } finally {
      // setIsLoading(false); // Désactiver le loader
      // setIsModalOpen(false);
    }
  };

  useEffect(() => {
    const boxHeight = boxRef.current?.clientHeight; // Hauteur de la boîte
    const textHeight = textRef.current?.clientHeight; // Hauteur du texte

    // Si la hauteur du texte est supérieure à la hauteur de la boîte
    if (textHeight > boxHeight) {
      setIsOverflow(true); // Définir l'état de débordement sur true
      setFontSize((prevSize) => prevSize - 1); // Diminuer la taille de police
    } else {
      setIsOverflow(false); // Définir l'état de débordement sur false
    }
  }, [fontSize]);

  const navigate = useNavigate();

  useEffect(() => {
    // setTimeout(5000);
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const remaining = startVoteTime - currentTime;
      if (remaining > 1000) setTimeRemaining(remaining);
      if (remaining < 1000) {
        setIsVoteTime(true);
        const remaining = endVoteTime - currentTime;
        if (remaining < 1000) navigate("/videoPage");
        setTimeRemaining(remaining);
      }
    }, 1000); // Mettre à jour toutes les secondes

    return () => clearInterval(interval); // Nettoyer le setInterval lorsque le composant est démonté
  }, [startVoteTime]); // [endTime] pour réinitialiser l'interval lorsque l'heure de fin change

  const hours = Math.floor(timeRemaining / (1000 * 60 * 60));
  const minutes = Math.floor((timeRemaining / (1000 * 60)) % 60);
  const seconds = Math.floor((timeRemaining / 1000) % 60);

  const formattedTime = () => (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {hours !== 0 && (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontSize: "larger", height: "33px" }}>
              {("0" + hours).slice(-3)}
            </span>
            <span style={{ fontSize: "xx-small" }}>hour</span>
          </div>
          <span style={{ margin: "0px 5px" }}>:</span>
        </>
      )}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ fontSize: "larger", height: "33px" }}>
          {("0" + minutes).slice(-2)}
        </span>
        <span style={{ fontSize: "xx-small" }}>mins</span>
      </div>
      <span style={{ margin: "0px 5px" }}>:</span>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ fontSize: "larger", height: "33px" }}>
          {("0" + seconds).slice(-2)}
        </span>
        <span style={{ fontSize: "xx-small" }}>secs</span>
      </div>
    </div>
  );

  const handleOpen = (gridIndex, songId) => {
    // console.log("songId:", songId);
    setSelectedGrid(gridIndex); // Mettre à jour l'index de la grille sélectionnée
    setModalData(songId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (localStorage.getItem("hasUserVoted") === "true") {
      navigate("/videoPage");
    }
  }, [localStorage.getItem("hasUserVoted")]);

  const handleSongClick = (song) => {
    const updatedSelectedSongs = [...selectedSongs];

    var newSong = {};

    remoteData.forEach((element) => {
      element.forEach((elt) => {
        elt.songId.forEach((el) => {
          if (el._id === song._id) {
            newSong.position = elt.position;
            newSong._id = song._id;
            newSong.id_playlist = myPlayListId;
            const copy = tabFinalMusic;
            // console.log("copy:", copy);
            if (copy.some((element) => element.position === newSong.position)) {
              // console.log("position found:", newSong.position);
              const myUpdate = tabFinalMusic.map((cMusic) => {
                if (cMusic.position === newSong.position) {
                  // console.log("yes match");
                  return { ...cMusic, _id: newSong._id };
                }
                return cMusic;
              });
              // console.log("myUpdate:", myUpdate);
              setTabFinalMusic(myUpdate);
              return;
            }

            setTabFinalMusic([...tabFinalMusic, newSong]);
          }
        });
      });
    });
    updatedSelectedSongs[selectedGrid] = song;
    setSelectedSongs(updatedSelectedSongs);
    // console.log("UpdatedSelectedSongs:", tabFinalMusic);
    handleClose();
  };
  const getSelectedSong = (song) => {
    if (tabFinalMusic.some((element) => element._id === song._id)) {
      return true;
    }
  };

  const renderSongList = (mysongs) => {
    const valuesWithPercentage = calculatePercentages(mysongs);
    // valuesWithPercentage.map((song) => console.log("songs: ", song));
    return valuesWithPercentage.map((song, index) => (
      <Box
        key={index}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
          alignItems: "center",
          "&:hover": {
            bgcolor: "rgba(255, 142, 108, 0.7)",
          },
          "&:active": {
            bgcolor: "rgba(255, 142, 108, 0.7)",
          },
          backgroundColor: getSelectedSong(song)
            ? "rgba(255, 142, 108, 0.7)"
            : "",
        }}
        onClick={() => handleSongClick(song)}
        // onPointerEnter={() => handlePointerEnter()}
        // onPointerOver={() => handlePointerEnter()}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            variant="plain"
            size="sm"
            sx={{
              color: song === selectedSongs[selectedGrid] ? "#fff" : "#000",
            }}
          >
            <Favorite />
          </IconButton>
          <Box>
            <Typography
              sx={{ fontWeight: "bold", fontSize: "15px", color: "#000" }}
            >
              {song.title}
            </Typography>
            <Typography
              sx={{ fontSize: "10px", color: "#000", fontStyle: "italic" }}
            >
              {song.album}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography sx={{ fontSize: "15px", color: "#000" }}>
            {song.percentage + "%"}
          </Typography>
        </Box>
      </Box>
    ));
  };

  return (
    <BackgroundImage
      imageUrlMobile={ImageBackground}
      imageUrlDesktop={ImageBackgroundWeb}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // width: window.innerWidth >= 769 ? "58%" : "93%",
          margin: "auto",
          paddingTop: 1,
          alignContent: "center",
        }}
      >
        <Box
          sx={{
            height: 40,
            "@media (min-height:740px)": {
              marginBottom: "50px",
            },
            "@media (min-height:800px)": {
              marginBottom: "84px",
            },
            "@media (min-height:900px)": {
              marginBottom: "100px",
            },
          }}
        >
          <Typography
            style={{
              color: "#FF8E6C",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              lineHeight: "18px",
            }}
          >
            <span className="poppins" style={{ fontWeight: "bold" }}>
              {infoPlayList?.nomplaylis}
            </span>
            <span
              className="poppins"
              style={{
                fontWeight: "bold",
                textShadow: "rgb(0, 0, 0) 1px 1px 5px",
              }}
            >
              {formaData}
            </span>
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="h2"
            style={{
              color: "rgb(251, 221, 46, 0.9)",
              textAlign: "center",
              textShadow: "rgb(0, 0, 0) 1px 1px 7px",
            }}
          >
            <span className="budmo">JUKE BOX</span>
          </Typography>
          <Typography
            variant="h2"
            style={{
              color: "#fff",
              textAlign: "center",
              marginTop: -28,
              marginBottom: 10,
            }}
          >
            <span
              className="budmo"
              style={{
                color: "rgb(251, 221, 46, 0.9)",
                textShadow: "rgb(0, 0, 0) 1px 1px 7px",
              }}
            >
              <span>TOUR</span> <span className="octothorpe">{years}</span>
            </span>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "-1rem",
          }}
        >
          <Typography
            variant="h5"
            component="h4"
            sx={{
              background: "#FFFFFF",
              padding: 1,
              borderRadius: 2,
              color: "rgb(255, 142, 108)",
              textAlign: "center",
              width: "100%",
            }}
          >
            <span className="scuba">{formattedTime()}</span>
          </Typography>
        </Box>
        <Typography
          variant="h7"
          component="h6"
          style={{ color: "#fff", textAlign: "center" }}
          gutterBottom
        >
          <span>Ouverture des votes à {formatStartVoteDate}</span>
          <br />
          <span>Fermeture des votes à {formatEndVoteDate}</span>
        </Typography>
        <VirtualizedList
          remoteData={remoteData}
          boxRef={boxRef}
          selectedSongs={selectedSongs}
          handleOpen={handleOpen}
          fontSize={fontSize}
        />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#fff",
              padding: "10px 10px",
              borderRadius: "5px",
              minWidth: "300px",
            }}
          >
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", height: 25 }}
            >
              <IconButton>
                <IoClose color="#000" onClick={handleClose} />
              </IconButton>
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <h2
                className="modal-title"
                style={{ paddingLeft: 10, color: "#000", lineHeight: "normal" }}
              >
                Choisissez parmi <br /> ces {modalData.length} titres
              </h2>
              <h2>% des votes</h2>
            </div>
            {renderSongList(modalData)}
          </div>
        </Modal>

        <Box
          sx={{
            display: "flex",
            mt: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isLoading ? (
            <Spinner
              thickness="20px"
              speed="0.65s"
              textAlign={"center"}
              color="#A14699"
              size="xl"
            />
          ) : (
            <Button
              variant="contained"
              size="large"
              style={{
                borderRadius: "50px", // Arrondir les coins
                backgroundColor:
                  tabFinalMusic < tabCompare
                    ? "rgba(154, 159, 153, 0.5)"
                    : "#FFFFFF", // Couleur de fond
                color:
                  tabFinalMusic < tabCompare
                    ? "rgb(255, 142, 108, 0.5)"
                    : "rgb(255, 142, 108, 0.9)",
                fontWeight: "bold",
                textTransform: "none",
                borderBottom: "5px solid rgb(255, 142, 108, 0.9)",
              }}
              onClick={handleSongSelection}
            >
              Valider ma playlist
            </Button>
          )}

          {isLoadingData && (
            <Spinner
              thickness="30px"
              speed="0.65s"
              textAlign={"center"}
              color="#A14699"
              size="xl"
            />
          )}
        </Box>

        {/* {isrequired === true && (
          <div
            style={{
              textAlign: "center",
              color: "red",
            }}
          >
            Sélectionner tous les choix
          </div>
        )} */}
      </Box>
      <CustomModal
        isOpen={isModalOpen}
        tabFinalMusics={tabFinalMusic}
        onClose={handleCloseModal}
        // onConfirm={handleConfirmModal}
        isLoading={isLoading}
        isRequired={isrequired}
        hasSubscribed={subscribed}
      />
    </BackgroundImage>
  );
};

export default Home;
